<template>
  <div>
    <h1>详情页</h1>
  <el-button disabled>默认按钮</el-button>
  <el-button type="primary" @click="handleClick">获取vuex数据</el-button>
  <el-button type="success" @click="getList">get请求</el-button>
  <el-button type="info" @click="setvuex">设置vuex</el-button>
  <el-button type="warning" @click="postList">post请求</el-button>
  <el-button type="danger"><router-link to="/index">去首页</router-link></el-button>
   <el-button type="success" @click="back">路由跳转</el-button>
  </div>
</template>

<script>
// import { computed, defineComponent, getCurrentInstance, onMounted, PropType, reactive, ref, toRefs } from 'vue';

import { defineComponent, onMounted, reactive, computed, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
export default defineComponent({

  // 2.x写法
  // methods: {
  //   handleClick () {
  //     this.$message.success('请求成功')
  //   }
  // }
  // 3.x写法
  setup (props, context) {
    // console.log(props)
    // 获取全局实例 proxy类似2.x的this
    const { proxy } = getCurrentInstance()
    const store = useStore()
    // 创建响应式对象，简单的用ref，复杂的用reactive
    const data = reactive({
      num: 0,
      // userInfo: computed(() => store.getters.getUserInfo)
      userInfo: computed(() => store.state.user.userInfo)
    })
    // 页面挂载
    onMounted(() => {
      // console.log(context)
    })
    // 方法调用
    function handleClick () {
      proxy.$message.success('请求成功')
      console.log(proxy.data.userInfo.name)
    }
    // get请求
    async function getList () {
      console.log(proxy)
      try {
        const res = await proxy.$api.getLogin({ value: 1 })
        console.log(res)
      } catch (error) {
        console.log(error)
      }
    }
    // post请求
    async function postList () {
      console.log(proxy)
      try {
        const res = await proxy.$api.postRegister({ value: 1, name: 'test' })
        console.log(res)
      } catch (error) {
        console.log(error)
      }
    }
    // 存储vuex
    function setvuex () {
      data.num++
      proxy.$store.commit('SET_USER_INFO', { name: 'vuex' + data.num })
    }
    function back () {
      // 使用路由
      proxy.$router.push({ path: 'index', query: { type: 1 } })
    }

    // 在setup定义的变量方法必须导出
    return {
      data,
      handleClick,
      getList,
      postList,
      setvuex,
      back
    }
  }

})
</script>

<style>

</style>
